const Tabs = ({ setActiveTab, activeTab }) => {
  return (
    <div
      style={{
        backgroundColor: "rgb(0, 59, 149)",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        gap: 5,
        paddingBottom: 20,
        paddingLeft: 0
      }}
    >
      <div
        style={{
          border: activeTab === "Flights" ? "1px solid white" : "1px solid transparent",
          borderRadius: 50,
          padding: "17px 22px",
          color: "white",
          fontWeight: 600,
          cursor: "pointer",
          display: "flex", // Ensure the contents of the div are laid out in a row
          alignItems: "center", // Align the image and text vertically
          backgroundColor:
            activeTab === "Flights"
              ? "rgba(255, 255, 255, 0.18)"
              : "transparent", // Very transparent white
        }}
        onClick={() => setActiveTab("Flights")}
      >
        <img src="/Air.png" alt="Air" style={{ marginRight: 12, height: 25 }} />
        Flights
      </div>
      
    </div>
  );
};

export default Tabs;
