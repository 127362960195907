import React, { useState } from "react";
import Navbar from "./navbar";
import Tabs from "./Tabs";
import Flights from "./Flights";
import Inspiration from "./inspiration";

function Search() {
  const [activeTab, setActiveTab] = useState('Flights');

  return (
    <div>
      <Navbar />
      <Tabs setActiveTab={setActiveTab} activeTab={activeTab} />
      {activeTab === 'Flights' && <Flights />}
      <Inspiration />
    </div>
  );
}

export default Search;