import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import "./App.css";

const Flights = () => {
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [originIata, setOriginIata] = useState("");
  const [destinationIata, setDestinationIata] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [passengerType, setPassengerType] = useState("adult");
  const [cabinClass, setCabinClass] = useState("economy");
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [passengerDetails, setPassengerDetails] = useState({
    id: "",
    born_on: "",
    email: "",
    family_name: "",
    gender: "",
    given_name: "",
    phone_number: "",
    title: "",
  });
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  // IATA Codes
  const fetchIataCodes = async (query) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/iata-codes?query=${query}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return Array.isArray(data) ? data : []; // Ensure data is an array
    } catch (error) {
      console.error("Error fetching IATA codes:", error);
      return [];
    }
  };

  // Suggestions
  const handleSuggestionsFetchRequested = async ({ value }) => {
    const suggestions = await fetchIataCodes(value);
    setSuggestions(suggestions); // Set suggestions state as an array
  };

  // Clear Suggestions
  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Selected Suggestions
  const handleSuggestionSelected =
    (inputName) =>
    (event, { suggestion, method }) => {
      if (method === "click" || method === "enter") {
        if (inputName === "origin") {
          setOrigin(suggestion.name);
          setOriginIata(suggestion.iata_code);
        } else if (inputName === "destination") {
          setDestination(suggestion.name);
          setDestinationIata(suggestion.iata_code);
        }
      }
    };

  // Render Suggestion
  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.name} ({suggestion.iata_code})
    </div>
  );

  // Get Suggestion Value
  const getSuggestionValue = (suggestion) => suggestion.name;

  // Search
  const handleSearch = async () => {
    setLoading(true);
    const requestData = {
      data: {
        slices: [
          {
            origin: originIata,
            destination: destinationIata,
            departure_date: departureDate,
          },
        ],
        passengers: [
          {
            type: passengerType,
          },
        ],
        cabin_class: cabinClass,
      },
    };

    console.log("Request Data:", requestData); // Log the request data

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/duffel-flights-list-offers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      console.log("Response Status:", response.status); // Log the response status

      const data = await response.json();
      console.log("Response Data:", data); // Log the response data

      if (response.ok) {
        setOffers(data.data.offers);
      } else {
        console.error("Error:", data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // Select Offer
  const handleSelectOffer = (offerId) => {
    setSelectedOfferId(offerId);
    const passengerId = offers[0]?.passengers[0]?.id || "";
    setPassengerDetails((prevDetails) => ({ ...prevDetails, id: passengerId }));
  };

  const handleCreateOrder = async () => {
    const requestData = {
      data: {
        type: "instant", // Change order type to "instant"
        passengers: [passengerDetails],
        selected_offers: [selectedOfferId],
      },
    };
  
    try {
      const response = await fetch(
        "http://localhost:5000/duffel-flights-create-orders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
  
      const data = await response.json();
      console.log("Order created:", data);
  
      if (!data.errors) {
        // Call the payment function after order creation
        const paymentSuccess = await handlePayment(data.data.order);
        if (paymentSuccess) {
          setOrderSuccess(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const handlePayment = async (order) => {
    setLoading(true);
    const paymentData = {
      data: {
        order_id: order.id, // Use the order id from the created order
        payment: {
          type: 'balance', // or 'arc_bsp_cash' or 'card' based on your requirement
          currency: order.total_currency, // Assuming order has a total_currency field
          amount: order.total_amount, // Assuming order has a total_amount field
        },
      },
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/duffel-flights-create-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });
  
      const data = await response.json();
      console.log('Payment successful:', data);
      return true; // Indicate payment success
    } catch (error) {
      console.error('Payment error:', error);
      return false; // Indicate payment failure
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="search-area">
        <div className="search">
          <Autosuggest
            inputProps={{
              placeholder: "Origin",
              value: origin,
              onChange: (e, { newValue }) => setOrigin(newValue),
              name: "origin",
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected("origin")}
            renderSuggestion={renderSuggestion}
            getSuggestionValue={getSuggestionValue}
          />
          <Autosuggest
            inputProps={{
              placeholder: "Destination",
              value: destination,
              onChange: (e, { newValue }) => setDestination(newValue),
              name: "destination",
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected("destination")}
            renderSuggestion={renderSuggestion}
            getSuggestionValue={getSuggestionValue}
          />
          <input
            type="date"
            placeholder="Departure Date"
            value={departureDate}
            onChange={(e) => setDepartureDate(e.target.value)}
          />
          <select
            value={passengerType}
            onChange={(e) => setPassengerType(e.target.value)}
          >
            <option value="adult">Adult</option>
            <option value="child">Child</option>
            <option value="infant_without_seat">Infant without seat</option>
          </select>
          <select
            value={cabinClass}
            onChange={(e) => setCabinClass(e.target.value)}
          >
            <option value="first">First</option>
            <option value="business">Business</option>
            <option value="premium_economy">Premium Economy</option>
            <option value="economy">Economy</option>
          </select>
          <button onClick={handleSearch}>Search</button>
        </div>
      </div>
      {loading && <div>Loading...</div>}
      <ul>
        {offers.map((offer, index) => (
          <li
            key={index}
            className="offer-item"
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              margin: "10px 0",
            }}
          >
            <div>Total Amount: {offer.total_amount}</div>
            {offer.slices.map((slice, sliceIndex) => (
              <div key={sliceIndex}>
                <div>Origin: {slice.origin.name}</div>
                <div>Destination: {slice.destination.name}</div>
                {slice.segments.length > 0 && (
                  <div>
                    <div>
                      Operating Carrier:{" "}
                      {slice.segments[0].operating_carrier.name}
                    </div>
                    <div>Departing At: {slice.segments[0].departing_at}</div>
                    <div>Duration: {slice.segments[0].duration}</div>
                    <div>Arriving At: {slice.segments[0].arriving_at}</div>
                  </div>
                )}
              </div>
            ))}
            <button onClick={() => handleSelectOffer(offer.id)}>Select</button>
          </li>
        ))}
      </ul>
      {selectedOfferId && !orderSuccess && (
        <div className="passenger-details">
          <h3>Passenger Details</h3>
          <input
            type="text"
            placeholder="Born On (YYYY-MM-DD)"
            value={passengerDetails.born_on}
            onChange={(e) =>
              setPassengerDetails({
                ...passengerDetails,
                born_on: e.target.value,
              })
            }
          />
          <input
            type="email"
            placeholder="Email"
            value={passengerDetails.email}
            onChange={(e) =>
              setPassengerDetails({
                ...passengerDetails,
                email: e.target.value,
              })
            }
          />
          <input
            type="text"
            placeholder="Family Name"
            value={passengerDetails.family_name}
            onChange={(e) =>
              setPassengerDetails({
                ...passengerDetails,
                family_name: e.target.value,
              })
            }
          />
          <input
            type="text"
            placeholder="Gender"
            value={passengerDetails.gender}
            onChange={(e) =>
              setPassengerDetails({
                ...passengerDetails,
                gender: e.target.value,
              })
            }
          />
          <input
            type="text"
            placeholder="Given Name"
            value={passengerDetails.given_name}
            onChange={(e) =>
              setPassengerDetails({
                ...passengerDetails,
                given_name: e.target.value,
              })
            }
          />
          <input
            type="text"
            placeholder="Phone Number"
            value={passengerDetails.phone_number}
            onChange={(e) =>
              setPassengerDetails({
                ...passengerDetails,
                phone_number: e.target.value,
              })
            }
          />
          <input
            type="text"
            placeholder="Title"
            value={passengerDetails.title}
            onChange={(e) =>
              setPassengerDetails({
                ...passengerDetails,
                title: e.target.value,
              })
            }
          />
          <button onClick={handleCreateOrder}>Create Order</button>
        </div>
      )}
      {orderSuccess && (
        <div className="order-success">
          <h3>Booking Successful!</h3>
          <p>Check your Duffel Orders Dashboard for booking.</p>
        </div>
      )}
    </div>
  );
};

export default Flights;
