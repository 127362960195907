import React from "react";
import Routing from "./Routing"; 

function App() {
  return (
    <div>
      <Routing /> 
    </div>
  );
}

export default App